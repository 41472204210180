import axios from "@/plugins/axios";

export async function getRevisions() {
    return await axios({
        methods: "get",
        url: "/admin/task/operator-revised-tasks",
    });
}

export async function getRevisionTask(params) {
    return await axios({
        methods: "get",
        url: "/admin/task/revised-task",
        params,
    });
}

export async function getVersionHistory(params) {
    return await axios({
        method: "get",
        url: "/admin/result/version-history",
        params,
    });
}
