import { removeMedia, uploadMedia } from "@/api/app/upload";

export default {
    namespace: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async uploadMedia({ commit }, data) {
            let response;

            try {
                response = await uploadMedia(data);
            } catch (error) {
                throw new Error(error);
            }

            return response;
        },

        async removeMedia({ commit }, data) {
            let response;

            try {
                response = await removeMedia(data);
            } catch (error) {
                throw new Error(error);
            }

            return response;
        },
    },
};
