// loading image
export async function imageLoader(src, width, height) {
    return await new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = () => {
            const dimension = calculateAspectRatioFit(image.width, image.height, width, height);

            resolve(dimension);
            reject();
        };

        image.src = src;
    });
}
// calculate aspect ratio of image
export async function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    return await new Promise((resolve, reject) => {
        let ratio = 0;

        if ((maxWidth / srcWidth) * srcHeight <= maxHeight) {
            ratio = maxWidth / srcWidth;
        } else {
            ratio = maxHeight / srcHeight;
        }

        resolve({
            width: srcWidth * ratio,
            height: srcHeight * ratio,
            ratio: ratio,
        });
        reject();
    });
}

// generate random string with specific length
export function randomStringGenerator(length = 10) {
    const str = Math.random().toString(36).substring(2, length);
    return `${str}.jpg`;
}

// debounce will execute function after the timeout
export function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

// replace arguments into string
export function sprintf(string, ...argv) {
    !argv.length ? string : sprintf((string = string.replace("$", argv.shift())), ...argv);
}

// generate random number between two numbers
export function randomNumberGenerator(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export async function createBase64Image(src) {
    const response = await fetch(src);
    const data = await response.blob();

    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            const file = new File([data], randomStringGenerator(14), {
                type: "image/jpeg",
            });

            reader.readAsDataURL(file);
            reader.onload = function (e) {
                resolve(e.target.result);
            };
        } catch (e) {
            reject(e);
        }
    });
}

// convert base64 data to binary
export function convertBase64ToBinaryData(data) {
    const imageData = btoa(data);
    const binary = window.atob(imageData);
    const length = binary.length;

    return new Uint8Array(length);
}

// download base64 data as image
export function downloadBase64Image(data) {
    const anchor = document.createElement("a");

    anchor.download = randomStringGenerator(14) + ".png";
    anchor.href = data;
    anchor.click();
}

export async function toDataURL(url) {
    return fetch(url)
        .then((response) => {
            return response.blob();
        })
        .then((blob) => {
            return URL.createObjectURL(blob);
        });
}

// download image
export async function downloadPhoto(image) {
    const filename = randomStringGenerator(10);
    const a = document.createElement("a");
    a.href = await toDataURL(image);
    a.download = `${filename}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}