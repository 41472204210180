import _ from "lodash";
import store from "@/store";
import api from "@/api/item-removal/segments/index";

export default {
    namespace: true,
    state: {
        segmentsColors: [],
        segmentsPoints: [],
        selectedSegments: [],
        segmentsObjectPoints: [],
        segmentProcessState: false,
    },
    getters: {
        getSegmentsColors: ({ segmentsColors }) => segmentsColors,
        getSegmentsPoints: ({ segmentsPoints }) => segmentsPoints,
        getSelectedSegments: ({ selectedSegments }) => selectedSegments,
        getSegmentProcessState: ({ segmentProcessState }) => segmentProcessState,
        getSegmentsObjectPoints: ({ segmentsObjectPoints }) => segmentsObjectPoints,
    },
    mutations: {
        dropSelectedSegments: (state) => (state.selectedSegments = []),
        setSegmentsColors: (state, colors) => (state.segmentsColors = colors),
        setSegmentsPoints: (state, points) => (state.segmentsPoints = points),
        addSelectedSegments: (state, segments) => state.selectedSegments.push(segments),
        setSegmentProcessState: (state, processState) => (state.segmentProcessState = processState),
        setSegmentsObjectPoints: (state, objectPoints) => (state.segmentsObjectPoints = objectPoints),
        removeSelectedSegments(state) {
            if (state.selectedSegments.length > 0) {
                state.selectedSegments.pop();
            }
        },
    },
    actions: {
        async fetchSegmentsData({ commit }, data) {
            return await api
                .fetchImageSegments(data)
                // .then((response) => {
                //     const data = JSON.parse(response.data);
                //     commit("setSegmentsColors", data.colors);

                //     return response;
                // })
                // .then((response) => {
                //     const data = JSON.parse(response.data);
                //     commit("setSegmentsPoints", data.segment_map);

                //     return response;
                // })
                // .then((response) => {
                //     const data = JSON.parse(response.data);
                //     commit("setSegmentsObjectPoints", data.object_points);
                // })
                // .catch((error) => {
                //     console.error(error);
                // });
        },
        async sendSegmentsData({ commit }, data) {
            return await api.sendSelectedSegments(data)
        },
        async addSegments({ commit }, segments) {
            commit("addSelectedSegments", segments);
            return await new Promise((resolve, reject) => {
                resolve(segments);
                reject();
            });
        },
        async changeSegmentProcessState({ commit }, processState) {
            return await new Promise((resolve, reject) => {
                commit("setSegmentProcessState", processState);
                resolve(processState);
                reject();
            });
        },
    },
};
