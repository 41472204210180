export default {
  namespace: true,
  state: {
      virtualStagingImage: undefined,
  },
  getters: {
      getVirtualStagingImage: ({ virtualStagingImage }) => virtualStagingImage,
  },
  mutations: {
      SET_VERSION_STAGING_iMAGE: (state, payload) => (state.virtualStagingImage = payload),
  },
  actions: {
      async setVirtualStagingImage({ commit }, state) {
          return new Promise((resolve, reject) => {
              commit("SET_VERSION_STAGING_iMAGE", state);
              resolve();
              reject();
          });
      },
  },
};
