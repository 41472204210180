export default [
    // jobs index
    {
        path: "/jobs",
        name: "jobs-index",
        component: () => import("@/views/jobs/Jobs.vue"),
        meta: {
            title: "Jobs",
            layout: "expanded",
            header: {
                title: "Jobs",
                subtitle: undefined,
                canNavigateWithoutToken: false,
            },
        },
    },
    // services
    {
        path: "/jobs/item-removal",
        name: "jobs-item-removal",
        component: () => import("@/views/jobs/services/ItemRemoval.vue"),
        meta: {
            title: "Item Removal",
            layout: "collapsed",
            header: {
                title: "Item Removal",
                subtitle: "Arrange Projects!",
                canNavigateWithoutToken: false,
            },
        },
    },
    {
        path: "/jobs/image-enhancement",
        name: "jobs-image-enhancement",
        component: () => import("@/views/jobs/services/ImageEnhancement.vue"),
        meta: {
            title: "Image Enhancement",
            layout: "collapsed",
            header: {
                title: "Image Enhancement",
                subtitle: "Arrange Projects!",
                canNavigateWithoutToken: false,
            },
        },
    },
    {
        path: "/jobs/virtual-staging",
        name: "jobs-virtual-staging",
        component: () => import("@/views/jobs/services/VirtualStaging.vue"),
        meta: {
            title: "Virtual Staging",
            layout: "collapsed",
            header: {
                title: "Virtual Staging",
                subtitle: "Arrange Projects!",
                canNavigateWithoutToken: false,
            },
        },
    },
    // revisions
    {
        path: "/jobs/revision/item-removal",
        name: "jobs-revision-item-removal",
        component: () => import("@/views/jobs/revisions/ItemRemoval.vue"),
        meta: {
            title: "IR Revision",
            layout: "collapsed",
            header: {
                title: "Item Removal",
                subtitle: undefined,
                canNavigateWithoutToken: false,
            },
        },
    },
    {
        path: "/jobs/revision/image-enhancement",
        name: "jobs-revision-image-enhancement",
        component: () => import("@/views/jobs/revisions/ImageEnhancement.vue"),
        meta: {
            title: "IE Revision",
            layout: "collapsed",
            header: {
                title: "Image Enhancement",
                subtitle: undefined,
                canNavigateWithoutToken: false,
            },
        },
    },
    {
        path: "/jobs/revision/virtual-staging",
        name: "jobs-revision-virtual-staging",
        component: () => import("@/views/jobs/revisions/VirtualStaging.vue"),
        meta: {
            title: "VS Revision",
            layout: "collapsed",
            header: {
                title: "Virtual Staging",
                subtitle: undefined,
                canNavigateWithoutToken: false,
            },
        },
    },
    // revision versions list
    {
        path: "/jobs/revision/",
        name: "jobs-revision",
        component: () => import("@/views/jobs/Versions.vue"),
        meta: {
            title: "Revision",
            layout: "collapsed",
            header: {
                title: "Revision",
                subtitle: undefined,
                canNavigateWithoutToken: false,
            },
        },
    },
    // previous tasks
    {
        path: "/jobs/revision/item-removal",
        name: "jobs-previous-item-removal",
        component: () => import("@/views/jobs/previous/ItemRemoval.vue"),
        meta: {
            title: "Confirmation",
            layout: "collapsed",
            header: {
                title: "Item Removal",
                subtitle: undefined,
                canNavigateWithoutToken: false,
            },
        },
    },
    {
        path: "/jobs/previous/image-enhancement",
        name: "jobs-previous-image-enhancement",
        component: () => import("@/views/jobs/previous/ImageEnhancement.vue"),
        meta: {
            title: "Confirmation",
            layout: "collapsed",
            header: {
                title: "Image Enhancement",
                subtitle: undefined,
                canNavigateWithoutToken: false,
            },
        },
    },
];
