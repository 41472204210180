import _ from "lodash";
import { confirmPreviousTask, getTask, getTasks, rejectTask } from "@/api/tasks";

export default {
    namespace: true,
    state: {
        tasks: [],
        task: undefined,
        serviceType: undefined,
        previousTask: undefined,
        tableFields: [
            {
                key: "_id",
                label: "Job ID#",
            },
            {
                key: "assignedByName",
                label: "Assigned By",
            },
            {
                key: "dueTime",
                label: "Due Time",
            },
            {
                key: "assignedDate",
                label: "Assigned Date",
            },
            {
                key: "actions",
                label: "Actions",
            },
        ],
    },
    getters: {
        getTask: ({ task }) => task,
        getTasks: ({ tasks }) => tasks,
        getServiceType: ({ serviceType }) => serviceType,
        getPreviousTask: ({ previousTask }) => previousTask,
    },
    mutations: {
        SET_TASK(state, payload) {
            state.task = payload;
            state.serviceType = payload.serviceType;
        },
        SET_PREVIOUS_TASK(state, payload) {
            state.previousTask = payload;
        },
        SET_TASKS(state, payload) {
            const tableKeys = _.map(state.tableFields, "key");
            state.tasks = _.map(payload, (item) => {
                return _.pick(item, tableKeys);
            });
        },
        REMOVE_PREVIOUS_TASK: (state) => (state.previousTask = undefined),
    },
    actions: {
        async fetchTask({ commit }, data) {
            let response;

            try {
                response = await getTask(data)
                    .then((response) => {
                        console.log(response);
                        commit("SET_PREVIOUS_TASK", response.data.previousResult);
                        commit("SET_TASK", response.data.task);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                throw new Error(error);
            }

            return response;
        },
        async fetchTasks({ commit }) {
            let response;

            try {
                response = await getTasks();
            } catch (error) {
                throw new Error(error);
            }

            commit("SET_TASKS", response.data.data);

            return response;
        },
        async confirmPreviousTask({ commit }, data) {
            let response;

            try {
                response = await confirmPreviousTask(data)
                    .then(() => {
                        commit("REMOVE_PREVIOUS_TASK");
                    })
                    .catch(() => {});
            } catch (error) {
                throw new Error(error);
            }

            return response;
        },

        async rejectTask(state, data) {
            let response;

            try {
                response = await rejectTask(data);
            } catch (error) {
                throw new Error(error);
            }

            return response;
        },
    },
};
