<template>
    <div id="app">
        <component :is="layout">
            <router-view />
        </component>
    </div>
</template>

<script>
const LayoutFull = () => import("@/layouts/Full.vue");
const LayoutExpanded = () => import("@/layouts/Expanded.vue");
const LayoutCollapsed = () => import("@/layouts/Collapsed.vue");

export default {
    components: {
        LayoutFull,
        LayoutExpanded,
        LayoutCollapsed,
    },
    computed: {
        layout() {
            if (this.$route.meta.layout === "expanded") return LayoutExpanded;
            if (this.$route.meta.layout === "collapsed") return LayoutCollapsed;
            return LayoutFull;
        },
    },
};
</script>

<style lang="scss">
</style>
