import axios from "@/plugins/axios";

export async function getTask(params) {
    return await axios({
        method: "get",
        url: "/admin/task/get-task",
        params,
    });
}

export async function getTasks() {
    return await axios({
        methods: "get",
        url: "/admin/task/assigned-tasks",
    });
}

export async function confirmPreviousTask(data) {
    return await axios({
        method: "post",
        url: "/admin/result/verify",
        data
    });
}

export async function rejectTask(data) {
    return await axios({
        method: "post",
        url: "/admin/result/reject",
        data
    });
}