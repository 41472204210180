import _ from "lodash";
import { imageLoader } from "@/helpers";

export default {
    namespace: true,
    state: {
        // global
        dimensions: {
            extra_large: {
                width: 1187,
                height: 666,
            },
            large: {
                width: 832,
                height: 468,
            },
            small: {
                width: 320,
                height: 180,
            },
        },
        // item removal
        aiPhotoStatus: false,
        previewImage: undefined,
        processImage: undefined,
        // virtual staging
        catalogProcessCatalogs: [],
        styleProcessCatalogs: [],
        selectedStyleCatalogs: [],
    },
    getters: {
        // global
        getExtraLargeWidth: ({ dimensions }) => dimensions.extra_large.width,
        getExtraLargeHeight: ({ dimensions }) => dimensions.extra_large.height,
        getLargeWidth: ({ dimensions }) => dimensions.large.width,
        getLargeHeight: ({ dimensions }) => dimensions.large.height,
        getSmallWidth: ({ dimensions }) => dimensions.small.width,
        getSmallHeight: ({ dimensions }) => dimensions.small.height,
        // item removal
        getAiPhotoState: ({ aiPhotoStatus }) => aiPhotoStatus,
        getPreviewImage: ({ previewImage }) => previewImage,
        getProcessImage: ({ processImage }) => processImage,
        // virtual staging
        getCatalogProcessCatalogs: ({ catalogProcessCatalogs }) => catalogProcessCatalogs,
        getStyleProcessCatalogs: ({ styleProcessCatalogs }) => styleProcessCatalogs,
        getSelectedStyleCatalogs: ({ selectedStyleCatalogs }) => selectedStyleCatalogs,
    },
    mutations: {
        // global
        setExtraLargeWidth: (state, width) => (state.dimensions.extra_large.width = width),
        setExtraLargeHeight: (state, height) => (state.dimensions.extra_large.height = height),
        setLargeWidth: (state, width) => (state.dimensions.large.width = width),
        setLargeHeight: (state, height) => (state.dimensions.large.height = height),
        setSmallWidth: (state, width) => (state.dimensions.small.width = width),
        setSmallHeight: (state, height) => (state.dimensions.small.height = height),
        // item removal
        setAiPhotoStatus: (state, status) => (state.aiPhotoStatus = status),
        setPreviewImage: (state, payload) => (state.previewImage = payload),
        setProcessImage: (state, payload) => (state.processImage = payload),
        // virtual staging
        setCatalogProcessCatalogs: (state, catalogs) => {
            let _catalogs = [];

            catalogs.forEach((catalog) => {
                imageLoader(catalog.src, state.dimensions.large.width, state.dimensions.large.height)
                    .then((result) => {
                        _catalogs.push({
                            ...catalog,
                            style: result,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });

            state.catalogProcessCatalogs = _catalogs;
        },
        setStyleProcessCatalogs: (state, catalogs) => {
            state.styleProcessCatalogs = catalogs;
        },
        toggleSelectedStyleCatalog: (state, catalog) => {
            _.remove(state.selectedStyleCatalogs, (_catalog) => {
                return _catalog.catalog_category_id === catalog.catalog_category_id;
            });
            const parentCatalog = _.find(state.styleProcessCatalogs, ["id", catalog.catalog_category_id]);
            state.selectedStyleCatalogs.push({ ...catalog, room_type_title: parentCatalog.name });
        },
        setCatalogActivity(state, catalog) {
            const catalogIndex = _.findIndex(state.styleProcessCatalogs, ["id", catalog.catalog_category_id]);
            const images = state.styleProcessCatalogs[catalogIndex].catalog_images;

            _.map(images, (image) => {
                if (image.name === catalog.name) image.is_active = true;
                else image.is_active = false;
                return image;
            });
        },
    },
    actions: {
        // item removal
        async changeAiPhotoStatus({ commit }, status) {
            return await new Promise((resolve, reject) => {
                commit("setAiPhotoStatus", status);
                resolve(status);
                reject();
            });
        },
        async fetchPreviewImage({ commit }, image) {
            return await new Promise((resolve, reject) => {
                commit("setPreviewImage", image);
                resolve();
                reject();
            });
        },
        async fetchProcessImage({ commit }, image) {
            return await new Promise((resolve, reject) => {
                commit("setProcessImage", image);
                resolve(image);
                reject();
            });
        },
        // virtual staging
        async fetchCatalogProcessCatalogs({ commit }, catalogs) {
            return await new Promise((resolve, reject) => {
                commit("setCatalogProcessCatalogs", catalogs);
                resolve(catalogs);
                reject();
            });
        },
        async fetchStyleProcessCatalogs({ commit }, catalogs) {
            return await new Promise((resolve, reject) => {
                commit("setStyleProcessCatalogs", catalogs);
                resolve(catalogs);
                reject();
            });
        },
        async changeCatalogActivity({ commit }, catalog) {
            return await new Promise((resolve, reject) => {
                resolve(catalog);
                reject(false);
            })
                .then((response) => {
                    commit("setCatalogActivity", response);

                    return response;
                })
                .then((response) => {
                    commit("toggleSelectedStyleCatalog", response);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
