import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueCompositionAPI from "@vue/composition-api";
import { BootstrapVue } from "bootstrap-vue";
import Vuelidate from "vuelidate";

Vue.use(VueCompositionAPI);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);

import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import "@/assets/scss/app.scss";

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
