import _ from "lodash";

export default {
    namespace: true,
    state: {
        focusMode: false,
        cursor: undefined,
        activeTabContent: undefined,
        originalPhotoSwitchState: false,
        histories: [],
        activeHistoryIndex: 0,
    },
    getters: {
        getCursor: ({ cursor }) => cursor,
        getFocusMode: ({ focusMode }) => focusMode,
        getHistories: ({ histories }) => histories,
        getOriginalPhoto: ({ histories }) => _.first(histories),
        getActiveTabContent: ({ activeTabContent }) => activeTabContent,
        getActiveHistoryIndex: ({ activeHistoryIndex }) => activeHistoryIndex,
        getOriginalPhotoSwitchState: ({ originalPhotoSwitchState }) => originalPhotoSwitchState,
        getProcessingPhoto: ({ histories, activeHistoryIndex }) => histories[activeHistoryIndex],
    },
    mutations: {
        setCursor: (state, cursor) => (state.cursor = cursor),
        setFocusMode: (state, payload) => (state.focusMode = payload),
        addOutput: (state, payload) => state.histories.push(payload),
        emptyHistories(state) {
            state.histories = [];
            state.activeHistoryIndex = 0
        },
        setActiveHistoryIndex: (state, index) => (state.activeHistoryIndex = index),
        setActiveTabContent: (state, payload) => (state.activeTabContent = payload),
        setOriginalPhotoSwitchState: (state, payload) => (state.originalPhotoSwitchState = payload),
    },
    actions: {
        async changeFocusMode({ commit }, focusMode) {
            return await new Promise((resolve, reject) => {
                commit("setFocusMode", focusMode);
                resolve(focusMode);
                reject();
            });
        },
        async changeOriginalPhotoSwitchState({ commit }, originalPhotoSwitchState) {
            return await new Promise((resolve, reject) => {
                commit("setOriginalPhotoSwitchState", originalPhotoSwitchState);
                resolve(originalPhotoSwitchState);
                reject();
            });
        },
        async addOutputToHistory({ commit }, data) {
            return await new Promise((resolve, reject) => {
                commit("addOutput", data);
                resolve(data);
                reject();
            });
        },
        async changeActiveHistoryIndex({ commit }, index) {
            return await new Promise((resolve, reject) => {
                commit("setActiveHistoryIndex", index);
                resolve(index);
                reject();
            });
        },
    },
};
