import axios from "@/plugins/axios";

export async function getJob(id) {
    return await axios({
        method: "get",
        url: "/admin/task/preview-task",
        params: {
            task_id: id,
        },
    });
}

export async function getOperators() {
    return await axios({
        method: "get",
        url: "/admin/user/operators",
    });
}

export async function assignJob(data) {
    return await axios({
        method: "post",
        url: "/admin/task/assign-task-to-somebody",
        data
    });
}

export async function finishJob(data) {
    return await axios({
        method: 'post',
        url: "/admin/task/finish",
        data
    })
}