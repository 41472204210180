export default [
    {
        path: "/error-404",
        name: "error-404",
        component: () => import("@views/pages/errors/Error404.vue"),
        meta: {
            title: "404 Not Found",
            layout: "full",
            canNavigateWithoutToken: true,
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@views/pages/Login.vue"),
        meta: {
            title: "Login",
            layout: "full",
            canNavigateWithoutToken: true,
        },
    },
    {
        path: "/notification",
        name: "notification-index",
        component: () => import("@/views/pages/Notifications.vue"),
        meta: {
            title: "Notifications",
            layout: "expanded",
            header: {
                title: "Notification",
                subtitle: "Welcome Mahdi",
                hash: undefined,
                canNavigateWithoutToken: false,
            },
        },
    },
    {
        path: "/account",
        name: "account-index",
        component: () => import("@/views/pages/Account.vue"),
        meta: {
            title: "Profile",
            layout: "expanded",
            header: {
                title: "My Account",
                subtitle: "Welcome Mahdi",
                hash: undefined,
                canNavigateWithoutToken: false,
            },
        },
    },
];
