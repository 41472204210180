import axios from "@/plugins/axios";

export async function uploadMedia(data) {
    return await axios({
        data,
        method: "post",
        url: "/admin/task/upload-media",
    });
}

export async function removeMedia(params) {
    return await axios({
        params,
        method: "post",
        url: "/admin/task/delete-media",
    });
}
