import Vue from "vue";
import Vuex from "vuex";

// Modules
import jobs from "./jobs";
import zoom from "./app/zoom";
import auth from "./auth/index";
import task from "./tasks/index";
import image from "./app/image";
import upload from "./app/upload";
import project from "./app/project";
import account from "./account";
import revision from "./app/revision";
import revisions from "./revisions";
import versionHistory from "./app/version-history";
import mask from "./item-removal/mask/index";
import segments from "./item-removal/segments/index";
import itemRemoval from "./item-removal/index";
import virtualStaging from "./virtual-staging/index";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        jobs,
        auth,
        task,
        zoom,
        mask,
        image,
        upload,
        project,
        account,
        revision,
        revisions,
        segments,
        itemRemoval,
        virtualStaging,
        versionHistory,
    },
    strict: process.env.DEV,
});
