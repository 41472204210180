import { getVersionHistory } from "@/api/revisions";
import _ from "lodash";

export default {
    namespace: true,
    state: {
        hasVersionHistory: true,
        versionHistory: undefined,
    },
    getters: {
        getVersionHistory: ({ versionHistory }) => versionHistory,
        getHasVersionHistory: ({ hasVersionHistory }) => hasVersionHistory,
    },
    mutations: {
        setVersionHistory: (state, payload) => state.versionHistory = payload,
        setHasVersionHistory: (state, hasVersionHistory) => (state.hasVersionHistory = hasVersionHistory),
    },
    actions: {
        async fetchVersionHistory({commit}, data) {
            let response;

            try {
                response = await getVersionHistory(data)
                .then((response) => {
                    commit('setVersionHistory', response.data);
                });
            } catch (error) {
                throw new Error(error);
            }

            return response;
        },
    },
};
