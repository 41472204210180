import _ from "lodash";
import { assignJob, finishJob, getJob, getOperators } from "@/api/jobs";

export default {
    namespace: true,
    state: {
        job: undefined,
        operators: [],
    },
    getters: {
        getJob: ({ job }) => job,
        getOperators: ({ operators }) => operators,
    },
    mutations: {
        SET_JOB(state, payload) {
            state.job = payload;
        },
        SET_OPERATORS(state, payload) {
            state.operators = payload;
        },
    },
    actions: {
        async fetchJob({ commit }, data) {
            let response;

            try {
                response = await getJob(data);
            } catch (error) {
                throw new Error(error);
            }

            commit("SET_JOB", response.data);

            return response;
        },
        async fetchOperators({ commit }) {
            let response;

            try {
                response = await getOperators();
            } catch (error) {
                throw new Error(error);
            }

            commit("SET_OPERATORS", response.data.data);

            return response;
        },
        async assignJob(state, data) {
            let response;

            try {
                response = await assignJob(data);
            } catch (error) {
                throw new Error(error);
            }

            return response;
        },
        async finishJob(state, data) {
            let response;

            try {
                response = await finishJob(data);
            } catch (error) {
                throw new Error(error);
            }

            return response;
        },
    },
};
