import _ from "lodash";
import { getRevisions, getRevisionTask } from "@/api/revisions";

export default {
    namespace: true,
    state: {
        revisions: [],
        tableFields: [
            {
                key: "_id",
                label: "Job ID#",
            },
            {
                key: "assignedByName",
                label: "Assigned By",
            },
            {
                key: "dueTime",
                label: "Due Time",
            },
            {
                key: "assignedDate",
                label: "Assigned Date",
            },
            {
                key: "actions",
                label: "Actions",
            },
        ],
        revisionTask: undefined,
        revisionServiceTypes: [],
    },
    getters: {
        getRevisions: ({ revisions }) => revisions,
        getTableFields: ({ tableFields }) => tableFields,
        getRevisionTask({ revisionTask }) {
            if (revisionTask) return revisionTask.task;
        },
        getRevisionResults({ revisionTask }) {
            if (revisionTask) return revisionTask.result;
        },
        getRevisionServiceTypes: ({ revisionServiceTypes }) => revisionServiceTypes,
    },
    mutations: {
        SET_REVISIONS(state, payload) {
            const tableKeys = _.map(state.tableFields, "key");
            state.revisions = _.map(payload, (item) => {
                return _.pick(item, tableKeys);
            });
        },
        SET_REVISION_TASK(state, payload) {
            state.revisionTask = payload;
        },
        SET_REVISION_SERVICE_TYPES(state, payload) {
            state.revisionServiceTypes = [];

            if (typeof payload === "object") {
                for (const key in payload) {
                    if (Object.hasOwnProperty.call(payload, key)) {
                        const type = payload[key];
                        switch (type) {
                            case "IE":
                                state.revisionServiceTypes.push({
                                    id: 1,
                                    name: "Image Enhancement",
                                    button_name: "IE Panel",
                                    route: {
                                        name: 'jobs-revision-image-enhancement'
                                    }
                                });
                                break;
                            case "IR":
                                state.revisionServiceTypes.push({
                                    id: 2,
                                    name: "Item Removal",
                                    button_name: "IR Panel",
                                    route: {
                                        name: 'jobs-revision-item-removal'
                                    }
                                });
                                break;
                            case "VS":
                                state.revisionServiceTypes.push({
                                    id: 3,
                                    name: "Virtual Staging",
                                    button_name: "VS Panel",
                                    route: {
                                        name: 'jobs-revision-virtual-staging'
                                    }
                                });
                                break;
                        }
                    }
                }
            }
        },
    },
    actions: {
        async fetchRevisions({ commit }) {
            let response;

            try {
                response = await getRevisions();
            } catch (error) {
                throw new Error(error);
            }

            commit("SET_REVISIONS", response.data.data);

            return response;
        },
        async fetchRevisionTask({ commit }, data) {
            let response;

            try {
                response = await getRevisionTask(data);
            } catch (error) {
                throw new Error(error);
            }

            commit("SET_REVISION_TASK", response.data.data);

            return response;
        },
    },
};
