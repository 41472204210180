import axiosIns from "@/plugins/axios";

function fetchImageSegments(data) {
    return axiosIns({
        url: "/admin/item-removal/segment",
        method: "post",
        data,
    });
}

function sendSelectedSegments(data) {
    return axiosIns({
        url: "/admin/item-removal/segment-mask",
        method: "post",
        data,
    });
}

export default {
    fetchImageSegments,
    sendSelectedSegments,
};
