import _ from "lodash";

export default {
    namespace: true,
    state: {
        hasLatestRevision: false,
        lastRevisionComments: [],
        lastRevisionRequireEditing: "",
    },
    getters: {
        getHasLatestRevision: ({ hasLatestRevision }) => hasLatestRevision,
        getLastRevisionComments: ({ lastRevisionComments }) => lastRevisionComments,
        getLastRevisionRequireEditing: ({ lastRevisionRequireEditing }) => lastRevisionRequireEditing,
    },
    mutations: {
        setHasLatestRevision: (state, hasLatestRevision) => (state.hasLatestRevision = hasLatestRevision),
        setLastRevisionComments: (state, getLastRevisionComments) => (state.lastRevisionComments = getLastRevisionComments),
        setLastRevisionRequireEditing: (state, lastRevisionRequireEditing) => {
            state.lastRevisionRequireEditing = _.chain(lastRevisionRequireEditing).map('name').join('/');
        },
    },
    actions: {
        fetchLastRevisionComments({ commit }, payload) {
            let response;

            try {
                response = new Promise((resolve, reject) => {
                    resolve(payload);
                    reject();
                });
            } catch (e) {
                throw new Error(e);
            }

            commit("setLastRevisionComments", payload);
            return response;
        },
        async fetchLastRevisionRequireEditing({ commit }, payload) {
            let response;

            try {
                response = await new Promise((resolve, reject) => {
                    resolve(payload);
                    reject();
                });
            } catch (e) {
                throw new Error(e);
            }

            commit("setLastRevisionRequireEditing", payload);
            return response;
        },
    },
};
