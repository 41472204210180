import axiosIns from "@/plugins/axios";

function sendMask(data) {
    return axiosIns({
        url: "/admin/item-removal/mask",
        method: "post",
        data,
    });
}

export default {
    sendMask,
};
