import axiosIns from "@/plugins/axios";

export function getProfile() {
    return axiosIns({
        url: "/user/profile",
        method: "get",
    });
}

export function updateProfile(data) {
    return axiosIns({
        url: "/user/profile",
        method: "patch",
        data
    });
}

export async function deleteProfilePhoto(data) {
    return axiosIns({
        url: "/user/profile/delete-image",
        method: "delete",
        data
    });
}

export async function updateProfilePhoto(data) {
    return axiosIns({
        url: "/user/profile/upload-image",
        method: "post",
        data
    });
}