import Vue from "vue";
import VueRouter from "vue-router";

import jobs from "./routes/jobs";
import pages from "./routes/pages";

import { checkUserLoggedIn } from "@/helpers/auth/index";
import { checkCanNavigateWithoutToken } from "@/helpers/auth/index";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        { path: "/", redirect: { name: "jobs-index" } },
        ...jobs,
        ...pages,
        {
            path: "*",
            redirect: "error-404",
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (checkCanNavigateWithoutToken(to)) {
        if (to.name == "login" && checkUserLoggedIn()) {
            return next({
                name: "jobs-index",
            });
        }
        return next();
    }
    if (!checkUserLoggedIn()) {
        return next({
            name: "login",
        });
    }

    return next();
});

router.afterEach(to => {
    if (to.meta.title) {
    document.title = `Revivoto - ${to.meta.title}`;
    }
  });

export default router;
