import _, { startCase } from "lodash";
import countries from "@/data/countries";
import { deleteProfilePhoto, getProfile, updateProfile, updateProfilePhoto } from "@/api/account/index";

export default {
    namespace: true,
    state: {
        profile: undefined,
        countries: countries,
        profilePhoto: undefined,
    },
    getters: {
        getProfile: ({ profile }) => profile,
        getCountries: ({ countries }) => countries,
        getProfilePhoto: ({ profilePhoto }) => profilePhoto,
    },
    mutations: {
        deleteProfilePhoto(state) {
            state.profilePhoto = undefined
        },
        setProfilePhoto(state, payload) {
            if (payload) 
                payload = process.env.VUE_APP_URL + payload
            state.profilePhoto = payload;
        },
        updatePasswords() {},
        SET_PROFILE(state, payload) {
            state.profile = payload;
        },
        searchInCountries(state, payload) {
            const regex = new RegExp(payload, "gi");
            const data = countries.filter((country) => {
                if (country.name.search(regex) > -1) {
                    return country;
                }
            });

            state.countries = data;
        },
    },
    actions: {
        async deleteProfilePhoto({commit}, payload) {
            let response;

            try {
                response = deleteProfilePhoto(payload)
            } catch (error) {
                throw new Error(error)
            }

            response
            .then(() => {
                commit('deleteProfilePhoto')
            })
            .catch(() => {})

            return response;
        },
        updateProfilePhoto({commit}, payload) {
            let response;

            try {
                response = updateProfilePhoto(payload)
            } catch (error) {
                throw new Error(error)
            }

            response
            .then((response) => {
                commit('setProfilePhoto', response.data.data.src)
            })
            .catch(() => {})

            return response;
        },
        
        async updateProfile({ commit }, payload) {
            let response;

            try {
                response = await updateProfile(payload);
            } catch (error) {
                throw new Error(error);
            }

            // mutation
            commit("SET_PROFILE", response.data.data);

            return response;
        },
        async getProfile({ commit }) {
            let response;

            try {
                response = await getProfile();
            } catch (error) {
                throw new Error(error);
            }

            // mutation
            commit("SET_PROFILE", response.data.data);

            return response;
        },
    },
};
